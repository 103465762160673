/* eslint-disable prettier/prettier */
// eslint-disable-next-line jsx-a11y/click-events-have-key-events
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react'
import { BullsHeaderProps } from './types'
import { Column, Container, Row } from '@/components/base/gridview'
import Image from '@/components/base/image'
import Anchor from '@/components/base/anchor/Anchor'
import Icon from '@/components/base/icon'
import { useApp, useWidth, path, functions } from '@wap-client/core'
import { pageService } from '@wap-client/services'
import { useRouter } from 'next/router'

const Header: React.FC<BullsHeaderProps> = ({ logo, menu }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const [status, setStatus] = useState<number | null>(null)
  const [logoSize, setLogoSize] = useState(200)

  useEffect(() => {
    const handleScroll = () => {
      const newLogoSize = Math.max(102, 200 - window.scrollY / 10)
      setLogoSize(newLogoSize)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  function toggleAccordion(index: number) {
    setStatus((prevState) => (prevState === index ? null : index))
  }

  const app = useApp()
  const router = useRouter()
  const width = useWidth()

  const handleLanguageSelection: (language: string) => void = (
    language: string
  ) => {
    pageService
      .findPath(app.environment, { path: app.page.path, language })
      .then((slug) => {
        router.push(path.app(app.environment, slug))
      })
      .catch(() => {
        const homepagePath = app.settings.homepage

        if (homepagePath) {
          pageService
            .findPath(app.environment, { path: homepagePath, language })
            .then((slug) => {
              router.push(path.app(app.environment, slug))
            })
        }
      })
  }

  const clickHandler = (event: any) => {
    event.preventDefault()
  }
  const openMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen)
  }
  const isOutsideModal = (e: any) => {
    if (isMobileMenuOpen && e.target.classList.contains('mobile-menu')) {
      setIsMobileMenuOpen(false)
      document.body.removeEventListener('click', isOutsideModal)
    }
  }
  useEffect(() => {
    document.body.style.overflow = isMobileMenuOpen ? 'hidden' : 'auto'
  }, [isMobileMenuOpen])

  if (isMobileMenuOpen) {
    document.body.addEventListener('click', isOutsideModal)
  }
  return (
    <React.Fragment>
      <header className="header">
        <Container size="extended">
          <Row xs={{ justify: 'around', align: 'center' }}>
            <Column className="header-logo-parent">
              <Anchor
                className="header-logo-parent-logo"
                href={app.settings.homepage}
              >
                <Image
                  className="header-logo-parent-logo"
                  {...logo}
                  alt=""
                  width={width > 860 ? logoSize : 120}
                />
              </Anchor>
            </Column>
            {width > 860 ? (
              <Column>
                <ul className="header-menu">
                  {Array.isArray(menu) &&
                    menu?.map((menuItem: any, index: number) => (
                      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                      <li className="first" key={index}>
                        <button
                          onClick={
                            menuItem.children.length > 0
                              ? clickHandler
                              : undefined
                          }
                        >
                          {menuItem.children.length ? (
                            <Anchor href={menuItem.href}>
                              {menuItem.title}{' '}
                              {/* {menuItem.children && (
                                <Icon name="keyboard-arrow-right" />
                              )} */}
                            </Anchor>
                          ) : (
                            <Anchor href={menuItem.href}>
                              {menuItem.title}{' '}
                            </Anchor>
                          )}
                          {menuItem.children.length ? (
                            <ul className="second">
                              {menuItem.children.map(
                                (subItem: any, subIndex: number) => (
                                  <li key={subIndex}>
                                    <Anchor href={subItem.href}>
                                      {subItem.title}
                                      {subItem.children.length ? (
                                        <Icon name="keyboard-arrow-up" />
                                      ) : (
                                        <></>
                                      )}
                                    </Anchor>
                                    {subItem.children.length ? (
                                      <ul className="third">
                                        {subItem.children.map(
                                          (
                                            subChildren: any,
                                            subChildrenIndex: number
                                          ) => (
                                            <li key={subChildrenIndex}>
                                              <Anchor href={subChildren.href}>
                                                {subChildren.title}
                                                {subChildren.children.length ? (
                                                  <Icon name="keyboard-arrow-up" />
                                                ) : (
                                                  <></>
                                                )}
                                              </Anchor>
                                              {subChildren.children.length ? (
                                                <ul className="fourth">
                                                  {subChildren.children.map(
                                                    (
                                                      subSubChildren: any,
                                                      subSubChildrenIndex: number
                                                    ) => (
                                                      <li
                                                        key={
                                                          subSubChildrenIndex
                                                        }
                                                      >
                                                        <Anchor
                                                          href={
                                                            subSubChildren.href
                                                          }
                                                        >
                                                          {subSubChildren.title}
                                                        </Anchor>
                                                      </li>
                                                    )
                                                  )}
                                                </ul>
                                              ) : (
                                                <></>
                                              )}
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    ) : (
                                      <></>
                                    )}
                                  </li>
                                )
                              )}
                            </ul>
                          ) : (
                            <></>
                          )}
                        </button>
                      </li>
                    ))}
                  {width > 860 && (
                    <button
                      onClick={() =>
                        handleLanguageSelection(
                          app.language === 'tr-tr' ? 'en-us' : 'tr-tr'
                        )
                      }
                      className="lang-btn"
                    >
                      {app.language.substring(0, 2)}
                    </button>
                  )}
                </ul>
              </Column>
            ) : (
              <>
                {width < 860 && (
                  <button
                    className="lang-btn"
                    onClick={() =>
                      handleLanguageSelection(
                        app.language === 'tr-tr' ? 'en-us' : 'tr-tr'
                      )
                    }
                  >
                    {app.language.substring(0, 2)}
                  </button>
                )}
                <button className="menu-button" onClick={openMenu}>
                  {isMobileMenuOpen ? (
                    <Icon size={'large'} name="clear" />
                  ) : (
                    <Icon size={'large'} name="menu" />
                  )}
                </button>
              </>
            )}
          </Row>
        </Container>
      </header>
      {width < 860 ? (
        <button
          className={`mobile-menu ${
            isMobileMenuOpen ? 'mobile-menu-show' : ''
          }`}
        >
          <ul className="">
            {Array.isArray(menu) &&
              menu?.map((menuItem: any, index: number) => (
                <div key={index}>
                  {menuItem.children ? (
                    <div className="menuItem">
                      {menuItem.children.length ? (
                        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                        <div
                          onClick={() => {
                            toggleAccordion(index)
                          }}
                        >
                          {menuItem.title}{' '}
                          {menuItem.children && (
                            <Icon name="arrow-drop-down" size={'large'} />
                          )}
                        </div>
                      ) : (
                        <Anchor href={menuItem.href}>{menuItem.title} </Anchor>
                      )}
                      {menuItem.children.length ? (
                        <div
                          className={functions.classnames(
                            'mobile-submenu',
                            status === index && 'mobile-submenu--show'
                          )}
                        >
                          {menuItem.children.map(
                            (subItem: any, subIndex: number) => (
                              <div key={subIndex}>
                                <Anchor
                                  onClick={() => setIsMobileMenuOpen(false)}
                                  href={subItem.href}
                                >
                                  {subItem.title}
                                </Anchor>
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <div className="menuItem">
                      {menuItem.children.length ? (
                        <Anchor
                          href={
                            !menuItem.children.length
                              ? menuItem.href
                              : undefined
                          }
                        >
                          {menuItem.title}{' '}
                          {menuItem.children && (
                            <Icon name="arrow-drop-down" size={'large'} />
                          )}
                        </Anchor>
                      ) : (
                        <Anchor href={menuItem.href}>{menuItem.title} </Anchor>
                      )}
                      {menuItem.children.length ? (
                        <div
                          className={functions.classnames(
                            'mobile-submenu',
                            status === index && 'mobile-submenu--show'
                          )}
                        >
                          {menuItem.children.map(
                            (subItem: any, subIndex: number) => (
                              <div key={subIndex}>
                                <Anchor
                                  onClick={() => setIsMobileMenuOpen(false)}
                                  href={subItem.href}
                                >
                                  {subItem.title}
                                </Anchor>
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                </div>
              ))}
          </ul>
        </button>
      ) : (
        <></>
      )}
    </React.Fragment>
  )
}

export default Header
